.today-offer-card-container {
  background-color: $base-gray;
  height: 133px;
  padding: 12px 31px;
  cursor: pointer;

  border-radius: 4px;
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(to right, $primary 0%, transparent 100%);
  border-image-slice: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .main-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .harvest-price {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;

      .product-harvest {
        color: $primary;
        font-size: 14px;
      }

      .price-info {
        font-size: 24px;
      }

      .price {
        font-size: 36px;
      }
    }

    .clock-status {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      span {
        display: flex;
        font-size: 14px;
        align-items: center;

        i {
          margin-right: 5px;
        }
      }

      label {
        color: $base-lighter-gray;
        font-size: 12px;
        margin-bottom: 0;
        margin-left: 5px;
      }

      .counter-offer-signal {
        position: absolute;
        background-color: $badge-rejected;
        top: -5px;
        left: 118px;
        width: 25px;
        height: 25px;
        text-align: center;
        padding-top: 5px;
        border-radius: 100%;

        i {
          font-size: 14px;
        }
      }
    }
  }

  .updated-price-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    label {
      color: $base-lighter-gray;
      font-size: 14px;
      margin: 0;
    }

    span {
      font-size: 16px;
      display: flex;
      align-items: center;
      margin-left: 3px;

      i {
        margin-left: 5px;
      }

      i::after {
        height: 10px;
      }
    }
  }

  @media only screen and(min-width: 768px) {
    width: 440px;

    .main-row {
      flex-direction: row;

      .harvest-price {
        margin-bottom: 0;
      }

      .clock-status {
        flex-direction: column;
        justify-content: flex-end;
      }
    }
  }
}

.today-offer-detail-modal {
  color: white;

  .title {
    font-size: 18px;
    text-align: left;
    margin-bottom: 30px;
  }

  .btn-section {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
  }

  @media only screen and(min-width: 768px) {
    height: 470px;
    width: 388px;
  }
}
