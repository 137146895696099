.date-picker {
  margin: 0.5rem 0;

  .form-control {
    border-radius: 0px 5px 5px 0;
    box-shadow: none;

    &:disabled {
      background-color: $base-light-dark;
      opacity: 0.7;
    }
  }
  .input-group-text {
    border-radius: 5px 0 0 5px;
    background-color: $primary;
    border-color: $primary;
    font-size: 18px;
  }

  input {
    height: 38px;
    border: none;
    background-color: $base-gray;
    color: white;
    font-size: 12px;

    &::placeholder {
      color: white;
    }
  }

  input:focus {
    background-color: $base-gray;
    color: white;

    > div {
      z-index: 15;
    }
  }

  .react-datepicker-wrapper {
    width: 100% !important;
    display: grid !important;
  }
}

.date-picker:focus {
  box-shadow: 0 0 0 0.2rem $primary25 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: $primary !important;
  font-weight: bold;
}
