.closed-sign-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  background-color: $dark;
  font-size: 14px;
  margin-bottom: 16px;

  .img {
    margin-bottom: 45px;
  }

  .title {
    text-transform: uppercase;
    color: $primary;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .msg {
    color: $base-lighter-gray;
  }
}
