.header-background-container {
  background: transparent linear-gradient(90deg, $base-gray 0%, $dark 100%) 0%
    0% no-repeat padding-box;
}

.header-container {
  display: flex;
  max-width: 1240px;
  color: #fff;
  padding-top: 16px;
  margin: auto;
  margin-bottom: 16px;
  // Ensures correct positioning for
  // notifications and user-settings
  position: relative;

  .logo-container {
    display: flex;
    justify-content: center;
    margin-left: 16px;

    i {
      width: 70px;
    }

    .ic-logo-orange {
      display: none;
    }
  }

  .header-logo {
    margin: auto;
    cursor: pointer;
  }

  .nav-container {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    margin-top: 8px;
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 8px;
    overflow-x: scroll;
    display: none;

    .selected {
      border-bottom: 3px solid transparent;
      border-image: linear-gradient(90deg, $primary 0, transparent);
      border-image-slice: 1;
    }

    .nav-item {
      margin-right: 16px;

      &:last-of-type {
        margin-right: 0;
        padding-right: 16px;
      }

      &:hover {
        border-bottom: 3px solid transparent;
        border-image: linear-gradient(90deg, $primary 0, transparent);
        border-image-slice: 1;
        background: #585c5c;
      }

      span {
        display: block;
        width: max-content;
      }
    }
  }

  .right-menu {
    display: none;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;
    margin-top: 16px;

    .nav-btn {
      display: flex;
      align-items: center;
      margin-right: 16px;

      &:first-of-type {
        position: relative;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        cursor: pointer;
      }

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
      }
    }

    .notification-badge {
      width: 14px;
      height: 13px;
      background-color: red;
      color: white;
      position: absolute;
      border-radius: 100%;
      top: -10px;
      color: white;
      left: 6px;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media only screen and(min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding-top: 0;
    margin-bottom: 55px;

    .logo-container {
      margin-left: 0;

      i {
        width: 200px;
      }

      .ic-logo-orange {
        display: block;

        &::after {
          margin-top: 10px;
          margin-left: 15px;
          height: 50px;
          width: 180px;
        }
      }

      .ic-logo-orange-no-text {
        display: none;
      }
    }

    .header-logo {
      margin: 0;
      margin-left: 30px;
    }

    .nav-container {
      display: flex;
      margin: 0;
      padding-bottom: 0;
      height: inherit;
      overflow-x: hidden;

      &-mobile {
        display: none;
      }

      .nav-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .right-menu {
      display: flex;
      margin-top: 0;
      margin-right: 30px;
      padding-bottom: 0;

      .nav-btn {
        i {
          font-size: 16px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media only screen and(min-width: 1024px) {
    .nav-container {
      .nav-item {
        width: 165px;
      }
    }
  }
}

.notifications-container {
  background-color: #323434;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 16px;
  position: relative;

  .notification p {
    margin-bottom: 0;
    padding-bottom: 1rem;
  }

  @media only screen and(min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 70px;
    width: 354px;
    height: 80px;
    padding: 6px 9px;
    border-left: 1px solid;
    border-bottom: 1px solid;
  }
}

.user-settings-container {
  background-color: #323434;
  text-align: center;
  color: #fff;
  font-size: 16px;
  padding: 16px;
  position: relative;

  .info {
    display: flex;
    flex-direction: column;

    .subtitle {
      font-size: 11px;
      color: $primary;
    }

    span {
      &:last-of-type {
        margin-bottom: 8px;
      }
    }
  }

  .menu-item {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    background: transparent linear-gradient(260deg, #585c5c, #323434) 0 0
      no-repeat padding-box;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;

    span {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .logout {
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;

    span {
      &:hover {
        cursor: pointer;
      }
    }
  }

  @media only screen and(min-width: 768px) {
    width: 354px;
    position: absolute;
    right: 0;
    top: 70px;
    border-left: 1px solid;
    border-bottom: 1px solid;

    .info {
      text-align: left;
    }

    .menu-item {
      align-items: flex-start;
      flex-direction: column;
    }
  }
}
