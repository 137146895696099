.contact-modal {
  @media only screen and(min-width: 768px) {
    padding: 30px 50px;
  }

  .img-section {
    margin-bottom: 30px;
  }

  .title-container {
    margin-bottom: 30px;

    span {
      font-size: 14px;
      color: $primary;
      text-transform: uppercase;
      line-height: 16px;
    }
  }

  .mail-section {
    font-size: 14px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;

    a {
      text-decoration: underline;
    }

    .phone,
    a {
      color: $primary;
    }
  }
}
