.counter-offer-container {
    color: white;    
    display: flex;
    justify-content: space-between;

    .section-title {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .general-info {
        display: flex;
        flex-direction: column;

        .title {
            font-size: 72px;
            line-height: 80px;
            margin-bottom: 25px;
        }
        
        p {
            font-size: 18px;
            color: $base-lighter-gray;
        }
    }

    .offer-info {
        width: 552px;
        background-color: $dark;
        padding: 23px 20px;

        h1 {
            color: $badge-rejected;
        }
      

        .counter-offer-section {
            margin-bottom: 35px;
            display: flex;
            flex-direction: column;
            
            .changes-table {
                
                
                .changes-row {
                    background-color: $base-gray;
                    &:nth-child(even) { background-color: $dark} 
                    display: flex;
                    justify-content: space-between;
                    padding: 8px 15px;
                    align-items: center;
                    
                    label { 
                        font-size: 14px;
                        font-weight: bold;
                        color: $primary;
                        margin: 0;
                    }
    
                    .changed-data {
                        font-size: 18px;
                        color: $base-lighter-gray;
                        
                        i {
                            margin: 0 8px;
                        }
    
                        .new-value {
                            color: white;
                        }
                    }
                }

            }
        }

        .offer-section {
            display: flex;
            flex-direction: column;
        }

        .use-terms-section {
            margin-top: 25px;
            font-size: 14px;
            text-align: center;
            color: $base-lighter-gray;

            p {
                margin: 0;
            }

            span {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .btn-section {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
        }
    }
}