.radio-input {
  cursor: pointer;
  margin-right: 10px;

  input {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  i {
    font-size: 15px;
    width: 10%;
    display: none;
  }

  input:checked + .label-container {
    border-color: $primary;
    color: $primary;
    i {
      display: block;
      margin-right: 5px;
    }
  }

  .label-container {
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid $base-lighter-gray;
    border-radius: 4px;
    min-width: 133px;
    min-height: 40px;
    text-align: center;

    label {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;
    }
  }

  @media only screen and(min-width: 768px) {
    &:last-child {
      margin-right: 0;
    }
  }
}
