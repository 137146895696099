@import "./Card/negotiationCard";

.negotiations-container {
  color: white;

  .slick-list {
    width: 100%;
  }

  .today-offer-card-container {
    @media only screen and(min-width: 768px) {
      width: 552px;
    }

    .price-info,
    .price {
      font-weight: normal;
    }

    .clock-status > span {
      font-weight: normal;
    }

    .updated-price-row > label {
      margin: 0;
    }

    .clock-status > div.counter-offer-signal > i {
      position: absolute;
      left: 6px;
    }
  }

  .title-row {
    font-weight: bold;
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 35px;
  }

  .today-offers-section {
    font-size: 36px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 50px;
    align-items: center;
    background-color: $dark;
    padding: 40px 36px;
    border-radius: 4px;
    box-shadow: 2px 2px 15px #00000029;

    @media only screen and(min-width: 768px) {
      flex-direction: row;
    }

    .title {
      line-height: 40px;
      position: relative;
      top: -15px;

      + div {
        width: 268px;
      }

      @media only screen and(min-width: 768px) {
        top: 0;

        + div {
          width: 500px;
        }
      }
    }
  }

  h4 {
    font-size: 14px;
  }

  .products-row {
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: space-between;
  }

  .filters-button {
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-bottom: 5px;
    color: $base-lighter-gray;
    cursor: pointer;

    span {
      margin-right: 9px;
    }
  }

  .bottom-border {
    border-bottom: 2px solid $base-gray;
    display: flex;
    flex-direction: column;
  }

  .filters-container {
    border-top: 2px solid $base-gray;
    margin-bottom: 29px;
    display: flex;
    padding: 14px 18px 10px 18px;
    background-color: $dark;
    justify-content: space-between;
    flex-wrap: wrap;

    .inputs-container {
      display: flex;
      align-items: flex-end;
      margin-bottom: 15px;
      flex-wrap: wrap;

      .until {
        font-size: 16px;
        margin: 0 15px 18px 15px;
      }

      label {
        text-transform: uppercase;
        color: $base-lighter-gray;
        font-size: 14px;
      }

      .filter {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .btn-section {
      display: flex;
      align-items: center;
    }
  }

  .offers-container {
    display: flex;
    flex-wrap: wrap;
    min-height: 150px;
  }

  @media only screen and(min-width: 768px) {
    .title-row {
      font-size: 72px;
      line-height: 75px;
      margin-bottom: 75px;
    }

    .filters-button {
      flex-direction: row;
    }

    .bottom-border {
      flex-direction: row;
    }
  }

  @media only screen and(min-width: 1024px) {
    .title-row {
      text-align: left;
    }
  }
}

.empty-indications-msg {
  display: flex;
  justify-content: space-between;
}

.empty-msg-container {
  min-height: 235px;
  background-color: $dark;
  padding: 30px 48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-left: 25px;

  .img {
    margin-bottom: 20px;
  }

  .title {
    text-transform: uppercase;
    font-size: 14px;
    color: $primary;
    font-weight: bold;
    margin-bottom: 21px;
  }

  .msg {
    color: $base-lighter-gray;
    font-size: 14px;
  }
}
