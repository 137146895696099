.forgot-password-modal {
  .title-container {
    margin-bottom: 30px;
    font-size: 14px;
    color: $primary;
    text-transform: uppercase;
    line-height: 16px;
  }

  input {
    width: 100%;
    border-radius: 4px;
    border: none;
    padding: 14px;
    outline: none;
    font-size: 14px;

    &:focus {
      box-shadow: 0 0 0 2pt $primary;
    }
  }

  .mail-section {
    span {
      font-size: 12px;
      color: white;
    }
  }

  button {
    width: 148px;
    height: 48px;
    background-color: $primary;
    color: black;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
}
