.footer-background-container {
  background-color: $dark;
}

.footer-container {
  max-width: 1240px;
  margin: auto;
  padding: 0 16px;
  padding-top: 25px;

  .footer-top-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;

    .ic-logo-orange {
      height: fit-content;
    }

    img {
      height: 100px;
      margin-top: 30px;
      width: -webkit-fill-available;
    }

    .footer-nav {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      font-size: 15px;

      a:first-of-type {
        padding-top: 18px;
      }

      a,
      span {
        color: #fff;
        cursor: pointer;
        text-decoration: none;

        padding-bottom: 18px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .footer-bottom-container {
    font-size: 14px;
    text-transform: uppercase;
    opacity: 0.7;
    letter-spacing: 0.53px;
    border-top: 1px solid #707070;
    padding-top: 23px;
    padding-bottom: 26px;
    text-align: center;
    color: #fff;
  }

  @media only screen and(min-width: 768px) {
    padding-top: 45px;

    .footer-top-container {
      flex-direction: row;
      justify-content: space-around;

      .ic-logo-orange::after {
        width: 400px;
        height: 100px;
        margin-top: 10px;
      }

      img {
        width: unset;
        margin-top: 0;
      }

      .footer-nav {
        a {
          &:first-of-type {
            padding-top: 0;
          }
        }
      }
    }
  }
}
