.today-offers-carousel {
  .title {
    font-size: 14px;
    color: $base-lighter-gray;
    text-transform: uppercase;
    margin-left: 5px;
    line-height: 35px;
    position: relative;
    top: -5px;

    @media only screen and(min-width: 768px) {
      top: 0;
    }
  }

  .today-offer-card-container {
    background-color: $dark;
  }

  .slick-slider.slick-initialized {
    .slick-prev {
      left: -5px;
    }

    .slick-next {
      right: -5px;
    }

    .slick-list {
      width: 288px;
      margin: auto;

      @media only screen and(min-width: 768px) {
        width: 450px;
      }
    }
  }
}

.create-offer-btn {
  font-size: 18px;
  line-height: 28px;
  margin-top: 12px;
  margin-bottom: 24px;
  cursor: pointer;
  background-color: $primary;
  color: black;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .title-row {
    font-size: 20px;
  }

  .brick-dot {
    display: none;
    border-radius: 100%;
    background-color: $primary;
    width: 8px;
    height: 8px;
    margin-right: 16px;
    margin-bottom: 24px;
  }

  .btn-title {
    font-size: 24px;
    display: none;
  }

  .btn-subtitle {
    font-size: 12px;
    color: $base-lighter-gray;
  }

  .indicative-row {
    display: none;
    flex-direction: column;
    align-items: flex-end;

    &-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media only screen and(min-width: 768px) {
    max-width: 256px;
    max-height: 250px;
    margin-top: 0;
    padding: 30px;
    display: block;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    background-color: $base-gray;
    color: white;

    .btn-title {
      display: block;
    }

    .brick-dot {
      display: block;
    }

    .indicative-row {
      display: flex;

      &-mobile {
        display: none;
      }
    }
  }
}

.today-offers-banner {
  width: 100%;
  background-color: $dark;
  height: 205px;
  display: flex;
  justify-content: space-between;
  padding: 40px 36px;
  align-items: center;
  margin-bottom: 50px;
  border-radius: 4px;
  box-shadow: 2px 2px 15px #00000029;

  &.regular-tab-item {
    min-width: 180px;
    margin-left: 2px;
    margin-right: 2px;

    svg {
      display: none;

      @media only screen and(min-width: 768px) {
        display: block;
      }
    }
  }
}

.section-divider {
  border: 1px solid #ffffff;
  opacity: 0.1;
  width: 100%;
}

.create-offer-row {
  text-align: center;
  padding-top: 10px;
  letter-spacing: 0.02rem;

  span {
    text-transform: uppercase;
    font-size: 12px;
    color: $base-gray;
  }
}

.prices-indications-container {
  color: #cdcece;

  .title-button-row {
    display: flex;
    flex-direction: column;

    .info-section {
      .date-title-container {
        display: flex;
        flex-direction: column;

        .date {
          font-size: 17px;
          display: none;
        }

        .title {
          font-size: 26px;
          line-height: 26px;
          font-weight: 700;
          color: #fff;
        }
      }

      p {
        font-size: 18px;
        line-height: 28px;
        margin-top: 12px;
        display: none;
      }
    }
  }

  .tab-container {
    min-height: 42px;
    display: flex;
    align-items: flex-end;
    overflow-x: scroll;
    margin-top: 16px;

    &::-webkit-scrollbar {
      display: none;
    }

    .harvest-product-tab {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #cdcece;
      font-size: 18px;
      padding-bottom: 10px;
      margin-right: 12px;

      &:nth-child(3) {
        span {
          width: max-content;
        }
      }

      svg {
        display: none;
      }

      &.soy-selected {
        border-bottom: 5px solid $soy;
        color: $soy;
      }

      &.wheat-selected {
        border-bottom: 5px solid $wheat;
        color: $wheat;
      }

      &.corn-selected {
        border-bottom: 5px solid $corn;
        color: $corn;
      }

      span {
        width: 100px;
        text-align: center;
        font-size: 16px;
      }
    }
  }

  .price-indication-loader {
    margin: 10px 0;

    .price-indication-row {
      display: flex;
      flex-direction: column;

      .card-list {
        color: #fff;
        align-items: center;
        height: 150px;
        text-overflow: ellipsis;

        h4 {
          font-size: 18px;
        }
      }
    }
  }

  .harvest-product-tab-border {
    border-top: 1px solid $base-lighter-dark;
    margin: -1px 0 -15px 0;
  }

  @media only screen and(min-width: 768px) {
    .title-button-row {
      .info-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;

        .date-title-container {
          .date {
            display: block;
            margin-bottom: 5px;
          }

          .title {
            font-size: 55px;
            line-height: 55px;
          }
        }

        p {
          margin-top: 0;
          width: 50%;
          display: block;
        }
      }
    }

    .tab-container {
      margin-top: 55px;

      .harvest-product-tab {
        width: 180px;

        svg {
          display: block;
        }

        span {
          font-size: unset;
        }
      }
    }

    .price-indication-loader {
      .price-indication-row {
        flex-direction: row;
      }
    }
  }

  @media only screen and(min-width: 1024px) {
    .title-button-row {
      .info-section {
        .date-title-container {
          .title {
            font-size: 75px;
            line-height: 75px;
          }
        }

        p {
          width: unset;
          font-size: 14px;
        }
      }
    }

    .tab-container {
      justify-content: space-between;
      overflow: hidden;

      .harvest-product-tab {
        span {
          width: unset;
        }
      }
    }
  }
}

.price-indication-card {
  @media only screen and (max-width: 767px) {
    display: none;
  }

  padding: 20px;
  width: 256px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  margin-right: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  background-color: $dark;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 4px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: $base-gray;

    .create-offer-row > span {
      color: white;
      text-decoration: underline;
    }
  }

  label {
    cursor: pointer;
  }

  i {
    display: flex;
    flex-direction: row-reverse;
  }

  .card-value {
    font-size: 33px;

    .currency,
    .unit {
      font-size: 19px;
    }
  }

  .detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;

    .payment-date {
      color: $primary;
    }

    span {
      font-size: 14px;
      color: #f58520;
    }
  }
}

.price-indication-card-mobile {
  @media only screen and (min-width: 768px) {
    display: none;
  }

  display: flex;
  margin-bottom: 25px;
  cursor: pointer;
  background-color: $base-gray;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 4px;

  &-content {
    padding: 15px;
    width: 95%;
  }

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-body,
  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-value {
    font-size: 33px;

    .currency,
    .unit {
      font-size: 19px;
    }
  }

  &-delivery {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &-detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-side-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    background-color: $dark;
    opacity: 0.5;

    .chevron-right {
      font-size: 70px;
      position: relative;
      top: -5px;
    }
  }

  &-harvest-year {
    font-size: 14px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: $dark;
  }

  label {
    cursor: pointer;
    font-weight: normal;
  }
}
