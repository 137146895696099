.pagination-container {
  display: flex;
  align-items: center;

  i {
    font-size: 16px;
    padding: 8px 10px;
    border: 1px solid white;
    border-radius: 10px;
    width: 33px;
    height: 33px;
    cursor: pointer;
  }
}

.pagination-index {
  border-radius: 10px;
}
