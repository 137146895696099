.success-msg-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;

  i {
    font-size: 120px;
    color: $primary;
    margin-bottom: 50px;
  }

  .main-message-section {
    font-weight: bold;
    color: $primary;
    text-transform: uppercase;
    margin-bottom: 16px;
    text-align: center;
  }

  .text-section {
    color: $base-lighter-gray;
    margin-bottom: 55px;
    text-align: center;
  }

  .btn-section {
    text-transform: uppercase;
  }
}
