.checkbox-input {
  margin-top: 15px;
  margin-bottom: 15px;

  .container {
    display: flex;
    align-items: center;
    position: relative;
    min-width: 20px;
    min-height: 20px;
    margin: 0;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0;
    span {
      cursor: pointer;
    }
    input:disabled {
      cursor: default;
    }
  }

  .checkbox-label {
    margin-left: 25px;
    font-weight: 400;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 5px;
  }

  .tooltip {
    font-size: 12px;
    padding-left: 30px;
    opacity: 0.6;
    position: relative;
    top: -10px;
  }

  .container input:disabled ~ .checkmark {
    background: lightgray;
    cursor: default;
  }

  .container input:checked ~ .checkmark {
    background-color: white;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 6px;
    top: 1px;
    width: 8px;
    height: 13px;
    border: solid $base-gray;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
