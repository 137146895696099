.radio-input {
  /* Customize the label (the container) */
  .container {
    font-weight: normal;
    font-size: 14px;
    color: $base-lighter-gray;
    display: block;
    position: relative;
    padding-left: 24px;
    padding-top: 2px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 1px;
    left: -6px;
    height: 24px;
    width: 24px;
    background-color: white;
    border-radius: 50%;
    border: solid white 2px;
  }

  /* When the radio button is checked */
  .container input:checked ~ .checkmark {
    border-color: $primary;
    background-color: $primary;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
  }

  .tooltip {
    font-size: 12px;
    padding-left: 30px;
    opacity: 0.6;
    position: relative;
    top: -10px;
  }
}
