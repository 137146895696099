.login-page {
  .login-card {
    width: 300px;
    height: 340px;
    border-radius: 15px;
    background-color: rgba(black, 0.5);
    margin-bottom: 16px;

    @media only screen and (min-width: 375px) {
      width: 368px;
    }
  }

  .checkbox-input {
    label {
      display: flex;
      align-items: center;
      padding-left: 0;

      input {
        margin-top: 4px;
        margin-right: 4px;
      }
    }
  }

  .login-container {
    background-image: url("../../Assets/Img/login_background_2023.jfif");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $base-dark;

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
  }

  .inner-login-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .logo-container {
    margin-top: 5%;
    margin-bottom: 24px;
  }

  label {
    color: white;
    font-size: 12px;
  }

  .login-input {
    width: 100%;
    border-radius: 4px;
    border: none;
    padding: 14px;
    outline: none;
    font-size: 14px;

    &:focus {
      box-shadow: 0 0 0 2pt $primary;
    }
  }

  .login-btn {
    width: 148px;
    height: 48px;
    background-color: $primary;
    color: black;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .register-login {
    text-align: center;
    color: white;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
  }

  a {
    color: white;
    text-decoration: underline;
  }

  .forgot-password {
    font-size: 12px;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}
