.ic-up-arrow::after {
  display: flex;
  content: "";
  width: 10px;
  height: 13px;
  background: transparent url("../Assets/Icons/ic-UpArrow.svg") no-repeat;
}

.ic-down-arrow::after {
  display: flex;
  content: "";
  width: 10px;
  height: 13px;
  background: transparent url("../Assets/Icons/ic-DownArrow.svg") no-repeat;
}

.ic-payment::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/ic-payment.svg") no-repeat;
}

.ic-weight::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/ic-quantidade.svg") no-repeat;
}

.ic-local::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/ic-local.svg") no-repeat;
}

.ic-port::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/ic-port.svg") no-repeat;
}

.ic-dollar::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/ic-valor-da-oferta.svg")
    no-repeat;
}

.ic-calendar::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/ic-calendar.svg") no-repeat;
}

.ic-archive::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/archive.svg") no-repeat;
}

.ic-clock::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/ic-timer.svg") no-repeat;
}

.ic-pack::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/ic-pack.svg") no-repeat;
}

.ic-industry::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/ic-industry.svg") no-repeat;
}

.ic-handshake::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/ic-handshake.svg") no-repeat;
}

.ic-eye::after {
  display: flex;
  content: "";
  width: 25px;
  height: 15px;
  background: transparent url("../Assets/Icons/ic-eye.svg") no-repeat;
}

.ic-locker::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/ic-locker.svg") no-repeat;
}

.ic-use-terms::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/ic-termosdeuso.svg") no-repeat;
}

.ic-logo-white::after {
  display: flex;
  content: "";
  width: 290px;
  height: 70px;
  background: transparent url("../Assets/Img/LOGO_white.svg") no-repeat;

  @media only screen and(min-width: 768px) {
    width: 300px;
  }
}

.ic-logo-orange::after {
  display: flex;
  content: "";
  width: 290px;
  height: 70px;
  background: transparent url("../Assets/Img/LOGO_orange.svg") no-repeat;

  @media only screen and(min-width: 768px) {
    width: 300px;
  }
}

.ic-logo-orange-no-text::after {
  display: flex;
  content: "";
  width: 290px;
  height: 70px;
  background: transparent url("../Assets/Img/LOGO_orange_no_text.svg") no-repeat;

  @media only screen and(min-width: 768px) {
    width: 300px;
  }
}

.ic-hamburger-menu::after {
  display: flex;
  content: "";
  width: 40px;
  height: 40px;
  background: transparent url("../Assets/Icons/ic-hamburger-menu.svg") no-repeat;
}
