@import "./header";
@import "./footer";

.home-page {
  overflow: hidden;
  position: relative;
}

.home-body {
  padding: 0 16px;
  max-width: 1240px;
  margin: auto;
  min-height: 50vh;

  @media only screen and (min-width: 1024px) {
    padding: 16px 45px;
    min-height: 100vh;
  }
}
