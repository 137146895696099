.nav-container-mobile {
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;

    &:checked {
      label {
        top: 20px;
      }

      ~ #sidebarMenu {
        transform: translateX(0);
      }

      ~ .sidebarIconToggle {
        > .horizontal {
          transition: all 0.3s;
          box-sizing: border-box;
          opacity: 0;
        }

        > .diagonal.part-1 {
          transition: all 0.3s;
          box-sizing: border-box;
          transform: rotate(135deg);
          margin-top: 8px;
          margin-bottom: 3px;
        }

        > .diagonal.part-2 {
          transition: all 0.3s;
          box-sizing: border-box;
          transform: rotate(-135deg);
          margin-top: -11px;
        }
      }
    }
  }

  .sidebarIconToggle {
    position: absolute;
    right: 16px;
    cursor: pointer;
    z-index: 99;
    height: 100%;
    height: 22px;
    width: 22px;
    transition: all 0.3s;
    box-sizing: border-box;
    margin-bottom: 0;

    .spinner {
      transition: all 0.3s;
      box-sizing: border-box;
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: #fff;
    }

    .horizontal {
      transition: all 0.3s;
      box-sizing: border-box;
      position: relative;
      float: left;
      margin-bottom: 3px;
    }

    .diagonal.part-1 {
      position: relative;
      transition: all 0.3s;
      box-sizing: border-box;
      float: left;
      margin-bottom: 2.5px;
    }

    .diagonal.part-2 {
      transition: all 0.3s;
      box-sizing: border-box;
      position: relative;
      float: left;
    }
  }

  #sidebarMenu {
    height: 101%;
    width: 100vw;
    position: fixed;
    left: 0;
    top: -2px;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
    background: $dark;
    z-index: 2;

    .sidebarMenuInner {
      margin: 0;
      padding: 0;
      padding-top: 55px;
      background-color: $dark;

      li {
        list-style: none;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        padding: 20px;
        cursor: pointer;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        width: 100vw;

        a,
        i {
          color: #fff;
          text-transform: uppercase;
          font-weight: bold;
          cursor: pointer;
          text-decoration: none;
        }

        &.my-account {
          ul {
            padding-top: 8px;
            padding-left: 0;

            li {
              border-bottom: 0;
              opacity: 0.7;
              padding-top: 8px;
              padding-bottom: 8px;
            }
          }
        }

        &.logout {
          border-bottom: 0;

          span {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media only screen and(min-width: 768px) {
    display: none;
  }
}
