.negotiation-details {
  color: white;
  display: flex;
  justify-content: space-between;

  .title-section {
    font-size: 72px;
    font-weight: bold;
    line-height: 85px;
    margin-right: 25px;
  }

  .offer-container {
    width: 552px;
    padding: 30px 20px;
    background-color: $dark;

    .offer-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .title {
        font-size: 18px;
      }

      .badge {
        font-size: 12px;
      }
    }

    .info-section {
      font-size: 14px;
      text-align: center;
      margin-bottom: 26px;
      margin-top: 26px;
      color: $base-lighter-gray;
    }

    .btn-section {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
