// Dark Theme
$dark: #2c3e4f;
$base-dark: #30363a;
$base-light-dark: #3a4149;
$base-lighter-dark: #9d9d9d;

$base-gray: #46525e;
$base-light-gray: #2c3e4f;
$base-lighter-gray: #cdcece;

$primary: #f58520;
$primary75: rgba($primary, 0.75);
$primary50: rgba($primary, 0.5);
$primary25: rgba($primary, 0.25);

$sidebar-nav-link-hover-bg: $primary !important;

$font-size-base: 0.9rem;

$spacer: 1.8rem;

$badge-active: #8cde35;
$badge-inactive: $base-lighter-dark;
$badge-new: #ffbe43;
$badge-rejected: #fb2b2b;

$soy: #f58520;
$wheat: #f58520;
$corn: #f58520;

$box-shadow: rgba(0, 0, 0, 0.2);

$sidebar-width: 165px;
