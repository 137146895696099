.negotiation-card {
  min-width: 345px;
  min-height: 320px;
  background-color: $dark;
  padding: 15px 12px;
  margin-bottom: 20px;
  margin-right: 8px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  .main-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 2px solid $base-gray;
    padding-bottom: 15px;

    .datetime-value {
      font-weight: normal;
      color: $base-lighter-gray;

      .date-time {
        color: $primary;
        font-size: 12px;
      }

      .value-currency {
        .currency {
          font-size: 18px;
          margin-right: 5px;
        }
        .cmu {
          font-size: 18px;
        }

        .value {
          font-size: 28px;
        }
      }
    }
  }

  .details-container {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    .info-row:first-child > span {
      text-transform: uppercase;
    }

    .info-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        text-transform: uppercase;
        color: $base-lighter-gray;
        letter-spacing: 0.07rem;
        margin-bottom: 0;
      }

      span {
        font-size: 14px;
      }
    }
  }
}
