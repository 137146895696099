.radio-input-card {
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100px;
  min-width: 90px;

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  input {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  i {
    font-size: 15px;
    width: 10%;
    display: block;
  }

  input:checked + .label-container > .top-circle-container > i:before {
    display: block;
  }

  input:checked + .label-container > .top-circle-container > i {
    background-color: $primary;
  }

  input:checked + .label-container {
    opacity: 1;
    background-color: $base-light-gray;
  }

  .label-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    border: 1px solid $base-lighter-gray;
    border-radius: 4px;
    height: 153px;
    opacity: 0.5;

    label {
      margin-bottom: 0;
      font-size: 14px !important;
      font-weight: normal;
      width: 80%;
      cursor: pointer;
      margin-top: 5px;
      text-transform: initial !important;
    }

    .top-circle-container {
      display: flex;
      flex-direction: row-reverse;

      i {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: $base-gray;

        &:before {
          position: relative;
          top: 4px;
          left: 5px;
          display: none;
        }
      }
    }
  }
}
