.register-container-background {
  background: transparent linear-gradient(119deg, $base-gray 0%, $dark 100%) 0%
    0% no-repeat padding-box;
  min-height: 100vh;
  width: 100vw;
}

.register-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  max-width: 1024px;
  margin: auto;

  .flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .logo-row {
    margin-top: 50px;
    margin-bottom: 28px;
  }

  .title-row {
    color: white;
    font-size: 36px;
    width: 100%;
    margin-bottom: 12px;
    text-align: center;
  }

  .register-form-container {
    width: 100%;
    min-height: 515px;
    background-color: $dark;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 4px;
    padding: 45px 30px;
    margin-bottom: 25px;

    .form-section {
      width: 50%;
      min-width: 300px;
      flex-grow: 1;

      &:nth-child(1) {
        border-right: 1px solid $base-gray;
        padding-right: 55px;
      }
    }

    .form-row {
      color: white;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .cpf-row {
        display: flex;
        flex-direction: column;
      }

      label {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.03rem;
      }

      .inova-input {
        width: 270px;
        height: 56px;
        background-color: transparent;
        border: 1px solid #585c5c;
        margin-bottom: 20px;
        padding-left: 5px;
      }
    }

    .btn-section {
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
    }

    .cards-container {
      width: 100%;
      margin-bottom: 30px;
      margin-top: 15px;
      display: grid;
      grid-template-areas: "card card";
    }
  }

  .success-msg-container {
    background-color: $dark;
    width: 348px;
    height: 382px;
    border-radius: 4px;
    box-shadow: 3px 3px 6px #00000029;
  }

  @media only screen and (min-width: 768px) {
    .title-row {
      text-align: left;
    }

    .register-form-container {
      .form-section {
        &:nth-child(2) {
          padding-left: 55px;
        }

        .form-row {
          .inova-input {
            width: 100%;
          }
        }
      }

      .cards-container {
        display: flex;
      }
    }
  }
}
