
.first-access-container {
    background: transparent linear-gradient(119deg, $base-gray 0%, $dark 100%) 0% 0% no-repeat padding-box;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;

    .logo-row {
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .title-row {
      font-size: 36px;
      margin-bottom: 50px;
    }

    .first-access-form-container {
      background-color: $dark;
      padding: 30px 27px;
      margin-bottom: 20px;
      width: 388px;
      height: 420px;

      .info-row {
        font-size: 14px;
        margin-bottom: 25px;
      }

      .form-row {
        display: flex;
        flex-direction: column;
        margin: 0;

        label {
          text-transform: uppercase;
        }

        .input-container {
          .inova-input {
            width: 100%;
            margin-bottom: 20px;
            background-color: transparent;
            border: 1px solid $base-gray;
          }
          
          position:relative;
          
          i {
            position:absolute;
            top: 13px;
            right: 10px;
            cursor: pointer;
          }
        }

      }

      .btn-row {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }

    .tc-container {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .use-terms-section {
        padding: 30px 40px;
        margin-bottom: 25px;
        font-size: 16px;
        background-color: $dark;
        width: 95%;
        border-radius: 4px;
        margin: auto;

        font {
          color: white !important;
        }
        
        p {
          margin-bottom: 30px;
        }
      }
  
      .btn-row {
        text-align: center;
      }

    }
    
  }