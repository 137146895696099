// Base Font - Lato from Google Fonts: https://fonts.google.com/specimen/Lato?selection.family=Lato
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
@import "~bootstrap/scss/bootstrap";
@import "variables";
@import "icons";

// Modules
@import "../Modules/Login/login";
@import "../Modules/Login/firstAccess";
@import "../Modules/UserPreRegister/userPreRegister";
@import "../Modules/Home/home";
@import "../Modules/PricesIndications/pricesIndications";
@import "../Modules/Offer/offerCreator";
@import "../Modules/Offer/CounterOffer/counterOffer";
@import "../Modules/Negotiations/negotiations";
@import "../Modules/Negotiations/Details/negotiationDetails";
@import "../Modules/ChangePass/changePass";
@import "../Modules/Offer/Card/todayOfferCard";
@import "../Modules/Home//sidemenu";

// Components
@import "../Components/RadioInput/radioInputCard";
@import "../Components/RadioInput/radioInputBtn";
@import "../Components/RadioInput/radioInput";
@import "../Components/CheckboxInput/checkboxInput";
@import "../Components/Modal/modal";
@import "../Components/SuccessMessageModal/successMessageModal";
@import "../Components/Pagination/pagination";
@import "../Components/ClosedSign/closedSign";
@import "../Components/DatePicker/datePicker.scss";
@import "../Components/ContactModal/contactModal";
@import "../Components/ForgotPasswordModal/forgotPasswordModal";

body {
  font-family: "Lato", -apple-system, sans-serif;
  background: transparent linear-gradient(138deg, $base-gray 0%, $dark 100%) 0%
    0% no-repeat padding-box;
}

input:disabled {
  &:hover {
    cursor: not-allowed;
  }
}

.circle-buttons-container {
  margin-top: 8px;
}

.inova-circle-btn {
  border: 1px solid $primary;
  border-radius: 100%;
  width: 40px;
  background-color: $primary;
  height: 40px;

  &:focus {
    outline: none;
  }

  i {
    font-size: 24px;
    color: $dark;
  }

  &.secondary {
    background-color: $dark;
    border-color: $base-gray;

    i {
      color: $base-gray;
    }
  }
}

.inova-btn {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  height: 38px;
  min-width: 100px;
  color: black;

  &:focus,
  &:focus:active {
    outline: none;
    box-shadow: none;
  }

  &.primary {
    background-color: $primary;
  }

  &.secondary {
    color: $primary;
    border: solid 1px;
  }

  &.approve {
    background-color: $primary;
  }

  &.reject {
    background-color: $badge-rejected;
  }

  &.indication {
    border-radius: 100%;
    height: 50px;
    min-width: 50px;
    font-size: 22px;
  }

  @media only screen and(min-width: 768px) {
    min-width: 150px;
  }
}

.inova-tab {
  z-index: 1;
  font-size: 14px;
  color: $base-lighter-dark;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  justify-content: center;
  cursor: pointer;

  &.small-tab-item {
    width: 110px;
  }

  &.regular-tab-item {
    width: 180px;

    svg {
      display: none;

      @media only screen and(min-width: 768px) {
        display: block;
      }
    }
  }

  &.large-tab-item {
    width: 200px;
  }

  .notification-container {
    position: relative;
    width: 0;
    height: 0;
  }

  .tab-notification {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 11px;
    font-weight: 900;
    color: white;
    top: -10px;
    left: 6px;
    background-color: $badge-rejected;

    &.selected {
      top: -9px;
    }
  }

  &.soy-selected {
    border-bottom: 5px solid $soy;
    color: $soy;
  }

  &.wheat-selected {
    border-bottom: 5px solid $wheat;
    color: $wheat;
  }

  &.corn-selected {
    border-bottom: 5px solid $corn;
    color: $corn;
  }

  &.tab-selected {
    border-bottom: 5px solid $primary;
    color: $primary;
  }
}

.inova-tab-border {
  z-index: 0;
  border-top: 1px solid $base-lighter-dark;
  margin: -1px -15px -15px -15px;
}

.inova-input {
  background-color: $base-gray;
  border: none;
  border-radius: 4px;
  font-size: 14px;

  &::placeholder {
    color: white;
    font-size: 12px;
  }

  &:focus {
    outline-color: $primary;
  }
}

.badge-container {
  height: 28px;
  font-size: 12px;
  letter-spacing: 1px;

  .badge {
    height: 22px;
    width: 100px;
    padding: 4px 17px;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: $base-lighter-gray;
    color: $dark;

    &.Aprovada {
      background-color: $primary;
    }

    &.Nova {
      background-color: $badge-new;
    }

    &.Rejeitada {
      background-color: $badge-rejected;
      color: black;
    }

    &.Consolidada {
      background-color: $base-lighter-gray;
    }
  }
}

.terms-open-container {
  background-color: $base-dark;
  font-size: 14px;
  padding: 16px;

  @media only screen and(min-width: 768px) {
    padding: 30px 120px;
  }
}

// React Pagination Ovewrites
.rc-pagination {
  color: black;

  .rc-pagination-item:hover a {
    color: black;
  }

  .rc-pagination-item {
    background-color: $base-lighter-gray;
    border-color: $base-lighter-gray;
    outline: none;

    &:hover {
      border-color: $base-lighter-gray;
      color: black;
      outline: none;
    }
  }

  .rc-pagination-item-active {
    background-color: $primary;
    border-color: $primary;

    &:hover {
      border-color: $primary;
      color: black;
      outline-color: none;
    }
  }
}

// React Slick Ovewrites
.slick-list {
  width: 450px;

  .slick-slide {
    display: flex !important;
    justify-content: center;
  }
}

.slick-dots li button:before {
  color: $primary !important;
}

.central-loader {
  position: relative;
  top: 50%;
  min-height: 100px;
}

.loader {
  height: 100%;
}

.brick-alert {
  font-size: 14px;
}
