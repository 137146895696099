.offer-creator-container {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .title {
      font-size: 26px;
      line-height: 26px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .subtitle {
      color: $base-lighter-gray;
      font-size: 18px;
      display: none;
    }
  }

  .short-form {
    width: 480px;
    min-height: 380px;
    padding: 3rem;
    box-shadow: none;
    background-color: $dark;
    border-radius: 4px;

    .form-row {
      display: flex;
      margin-bottom: 30px;
    }

    .label-row {
      display: flex;
      height: 16px;
      margin-bottom: 12px;
      width: 100%;

      i {
        margin-right: 15px;
      }

      label {
        line-height: 16px;
        letter-spacing: 0.05rem;
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    .input-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-left: 31px;
      width: 100%;
      font-size: 14px;

      .indication-value {
        margin-left: 15px;
      }

      input {
        width: 118px;
        height: 40px;
        background-color: #585c5c;
        border: none;
        border-radius: 4px;
        padding: 11px 16px;
        margin-right: 15px;
      }

      .inova-circle-btn {
        margin-right: 5px !important;
      }
    }
  }

  .loadedContent {
    .form-section {
      background-color: $dark;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
      border-radius: 4px;
      padding: 16px;
      margin-bottom: 16px;

      .form-row {
        &.offer-expiring-date {
          .input-row {
            align-items: baseline;

            label.container {
              padding-left: 28px;
              max-width: unset !important;
            }

            .checkmark {
              left: 0 !important;
            }

            .inova-circle-btn {
              i {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }

        .inova-btn {
          width: 100%;
        }
      }

      .label-row {
        display: flex;
        min-height: 16px;
        margin-bottom: 12px;
        width: 100%;

        label {
          line-height: 16px;
          letter-spacing: 0.05rem;
          font-size: 14px;
          margin-bottom: 0;
        }

        i {
          margin-right: 15px;
        }
      }

      .input-row {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        font-size: 14px;
        margin-bottom: 12px;
        margin-left: 32px;
        width: 100%;
        margin-right: 32px;

        .radio-input {
          min-width: 155px;
          width: 100%;

          input {
            display: none;

            &:last-child {
              margin-right: 0;
            }

            &:checked {
              + .label-container {
                border-color: $primary;
                color: $primary;

                i {
                  display: block;
                  margin-right: 10px;
                }

                label {
                  color: $primary;
                }
              }
            }
          }

          .label-container {
            padding: 10px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: 1px solid #cdcece;
            border-radius: 4px;
            min-width: 133px;
            min-height: 40px;
            text-align: center;

            i {
              font-size: 15px;
              width: 10%;
              display: none;
            }

            label {
              margin-bottom: 0;
              font-size: 14px;
              font-weight: 400;
              cursor: pointer;
            }
          }
        }

        .inova-input {
          width: 100%;
          height: 40px;
          background-color: $base-gray;
          border: none;
          border-radius: 4px;
          padding: 11px 16px;
          margin-bottom: 5px;
          margin-right: 5px;

          &:disabled {
            background-color: $base-light-dark;
            opacity: 0.7;
          }
        }

        .css-2b097c-container {
          margin-bottom: 12px;
        }

        .checkbox-input {
          display: flex;
          align-items: center;

          input[type="checkbox"] {
            margin-right: 15px;
          }

          span {
            cursor: pointer;

            &.checkmark {
              background-color: #fff;
              height: 20px;
              width: 20px;
            }
          }
        }

        .time-increment-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 5px 0;

          .inova-input {
            margin-bottom: 0;
            margin-right: 8px;
            width: 99px;
          }

          .circle-buttons-container {
            margin-top: 0;
          }
        }
      }
    }
  }

  @media only screen and(min-width: 768px) {
    flex-direction: row;

    .title-section {
      margin-right: 20px;
      margin-bottom: 15px;

      .title {
        font-size: 75px;
        line-height: 85px;
        font-weight: bold;
        margin-bottom: 30px;
      }

      .subtitle {
        display: block;
      }
    }

    .loadedContent {
      .form-section {
        padding: 40px;
        min-height: 1140px;

        .form-row {
          .inova-btn {
            width: unset;
          }

          .label-row {
            justify-content: flex-start;
          }

          &.offer-expiring-date {
            justify-content: flex-start;

            .input-row {
              align-items: center;
            }
          }

          .input-row {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding-left: 31px;

            .inova-input {
              width: 118px;
            }

            .radio-input {
              margin-right: 10px;
              width: unset;
            }

            .checkbox-input {
              .container {
                justify-content: flex-start;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and(min-width: 1024px) {
    .loadedContent {
      .form-section {
        width: 552px;

        .form-row {
          .input-row {
            justify-content: unset;

            .radio-input {
              min-width: 0;
            }
          }
        }
      }
    }
  }
}

.offer-confirmation-modal {
  color: white;
  display: flex;
  flex-direction: column;

  .title {
    text-align: left;
    font-size: 18px;
    margin-bottom: 30px;
  }

  .info-section {
    margin-bottom: 26px;

    div {
      display: flex;
      justify-content: space-between;
      background-color: $dark;
      padding: 8px 15px;
      font-size: 14px;

      &:nth-child(odd) {
        background-color: $base-gray;
      }
    }
  }

  .terms-of-use-section {
    margin: 25px 0;
    font-size: 14px;
    color: $base-lighter-gray;

    span {
      text-decoration: underline;
      color: white;
      cursor: pointer;
    }
  }

  .buttons-section {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
  }

  @media only screen and(min-width: 768px) {
    width: 388px;
    min-height: 510px;
  }
}

.offer-summary {
  div {
    display: flex;
    justify-content: space-between;
    background-color: $dark;
    padding: 8px 15px;
    font-size: 14px;
    &:nth-child(odd) {
      background-color: $base-gray;
    }
  }

  .timer {
    display: flex;
    align-items: center;
    color: $primary;

    i {
      margin-right: 10px;
    }
  }
}
