.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.modal-container {
  max-width: fit-content;
  min-width: 150px;
  min-height: 150px;
  z-index: 100;
  background: $dark;
  position: relative;
  margin: 11.2rem auto;
  border-radius: 3px;
  pointer-events: auto;
  overflow: auto;

  .pointer-events-auto {
    .loadedContent {
      .offer-creator-container {
        .short-form {
          width: unset;

          .indication-value {
            margin-left: 0;
            margin-top: 5px;
          }

          .radio-input {
            .container {
              margin-top: 10px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.pointer-events-auto {
  pointer-events: auto;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  margin-top: -15px;
  margin-right: -5px;
  border: none;
  padding: 0px;
}

.modal-close-button {
  padding: 0;
  background: none;
  margin: -1rem -1rem -1rem auto;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1;
  color: white;
  cursor: pointer;
  border: none;
  outline: 0;
}

.modal-content-text {
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
