.change-pass-container {
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  max-width: 1024px;

  .title {
    font-size: 36px;
    margin-bottom: 0;
  }

  .form-container {
    margin-top: 15px;
    background-color: $dark;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 15px;
    margin-bottom: 16px;
    width: 120%;

    form {
      width: 100%;
    }

    .form-row {
      display: flex;
      flex-direction: column;
      margin: 0;

      label {
        text-transform: uppercase;
        font-size: 12px;
      }

      .input-container {
        .inova-input {
          width: 100%;
          margin-bottom: 20px;
          background-color: transparent;
          border: 1px solid $base-gray;
        }

        position: relative;

        i {
          position: absolute;
          top: 5px;
          right: 10px;
          cursor: pointer;
        }
      }
    }

    .btn-section {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
  }

  .change-pass-loader {
    top: 50px;
  }

  .loadedContent {
    min-width: 265px;
    width: 80%;
  }

  @media only screen and(min-width: 768px) {
    .form-container {
      width: 388px;
    }

    .loadedContent {
      min-width: 355px;
    }
  }
}
